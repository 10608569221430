import React from 'react';
import './TopBar.css';
import linkedInLogo from '../../assets/global/valor-nav-linkedin.svg';
import facebookLogo from '../../assets/global/valor-nav-facebook.svg';

export default class TopBar extends React.Component {
    render(){
        return(
            <div className="sticky-top top-bar container-fluid">
                <div className="d-flex justify-content-lg-end justify-content-center text-white">
                    <div className="d-lg-inline-block  social-links my-auto">
                        <a href="https://linkedin.com/company/valordigital" className="mr-1"><img src={linkedInLogo} alt="Valor Digital Solutions Linked In" height="25"/></a>
                        <a href="https://www.facebook.com/valordigitalsolutions"><img src={facebookLogo} alt="Valor Digital Solutions Facebook" height="25"/></a>
                    </div>
                    <a href="/contact">Contact Us</a>
                </div>
            </div>
        )
    }
} 