import React from 'react';
import './HomeOurMission.css';
import { Row, Col } from 'reactstrap';
import ourMissionImage from '../../assets/home/valor-digital-solutions-our-mission.jpg';

export default class HomeOurMission extends React.Component {
    render(){
        return(
            <Row className="valor-sec sec-ourmission justify-content-center mr-0 ml-0">
                <Col md="6" lg="4" className="d-flex om-text">
                    <div className="m-auto">
                        <div>
                            <h1 className="sec-header">Our Mission</h1>
                        </div>
                        <div className="pr-0 mb-4 mb-md-0">
                            <p className="mob-para">
                                We aim to deliver solutions and services that differ from the competition. This is achieved by our method of understanding your business and vision and then determinig what is fit for purpose.
                            </p>
                            <p className="mob-para">
                                Our approach is to effectively separate the work into several phases. Contact us for an initial consultation where we will detail how our team can make a positive difference to your business.  
                            </p>
                        </div>
                    </div>
                </Col>
                <Col md="6" lg="4" className="d-flex om-image">
                    <div className="my-auto">
                        <img src={ourMissionImage} alt="Valor Digital Solutions" width="100%"/>
                    </div>
                </Col>
            </Row> 
        );
    }
}