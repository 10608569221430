import React from 'react';
import './ValorBottomNav.css';
import logo from '../../assets/global/valor-digital-solutions.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import linkedinFollow from '../../assets/global/valor-digital-solutions-linkedin-follow.png';
import facebookLike from '../../assets/global/valor-digital-solutions-facebook-like.png';

library.add(faEnvelope, faPhone);

export default class ValorBottomNav extends React.Component {
    render(){
        return(
            <div className="container-fluid">
                <div className="row valor-footer">
                    <div className="col-lg-3 col-md-6 col-12 pb-5 pb-lg-0">
                        <img src={logo} width="220" alt="Valor Digital Solutions"/>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 pb-4 pb-lg-0">
                        <ul className="list-unstyled">
                            <li><a href="/">Home</a></li>
                            <li><a href="/about">About Us</a></li>
                            <li><a href="/services">Services</a></li>
                            <li><a href="/solutions">Solutions</a></li>
                            <li><a href="/contact">Contact Us</a></li>
                        </ul>
                        <div className="pt-3">
                            <ul className="list-unstyled">
                            <li><FontAwesomeIcon icon="phone" rotation={90} /><span className="pl-3">02 8007 3277</span></li>
                            <li><i className="mr-3"><FontAwesomeIcon icon="envelope" /></i><span>info@ValorDigital.com.au</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <h4>Services</h4>
                        <hr className="footer-hr" align="left"/>
                        <ul className="list-unstyled">
                            <li>Digital Marketing</li>
                            <li>Consulting</li>
                            <li>UX Services</li>
                            <li>Software Development</li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12 pb-4 pb-lg-0">
                        <h4>Solutions</h4>
                        <hr className="footer-hr" align="left"/>
                        <ul className="list-unstyled">
                            <li>Web Solutions</li>
                            <li>CRM Systems</li>
                            <li>Mobile Apps</li>
                            <li>Cloud Services</li>
                        </ul>
                    </div>

                </div>
                <div className="row dark-footer">
                    <div className="col-12 col-lg-6 pb-4 pb-lg-0 d-flex justify-content-lg-start justify-content-center">
                        <p className="my-auto"></p>
                    </div>
                    <div className="col-12 col-lg-6 d-flex justify-content-lg-end justify-content-center">
                        <p className="my-auto">Connect with us</p>
                        <a className="ml-md-4 ml-2" href="/">
                            <img src={linkedinFollow} alt="LinkedIn Valor Digital Solutions" height="25"/>
                        </a>
                        <a className="ml-md-3 ml-1" href="https://www.facebook.com/valordigitalsolutions"><img src={facebookLike} alt="Facebook Valor Digital Solutions" height="25"/></a>

                    </div>
                </div>
            </div>
        )
    }
}