import React from "react";
import HomeCarousel from "../components/home/HomeCarousel";
import HomePixelArt from "../components/home/HomePixelArt";
import HomeWWD from "../components/home/HomeWWD";
import HomeOurMission from "../components/home/HomeOurMission";
import GetInTouchToday from "../components/global/GetInTouchToday";
import Layout from "../components/global/layout";
import SEO from "../components/global/seo";
import {graphql, StaticQuery} from "gatsby";

export default () => (
    <StaticQuery
        query={graphql`
      query HomePageQuery {
        allStrapiHomepage {
          edges {
            node {
              MetaDescription
            }
          }
        }
      }
    `}
        render={data => (
            <Layout>
                {data.allStrapiHomepage.edges.map(document => (
                    <SEO
                        title="Home"
                        keywords={[`valor`, `digital solutions`, `digital marketing`]}
                        description={document.node.MetaDescription}
                    />
                ))}
                <HomeCarousel />
                <HomePixelArt />
                <HomeWWD />
                <HomeOurMission />
                <GetInTouchToday />
            </Layout>
        )}
    />
);