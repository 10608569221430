import React from 'react';
import './ValorNavbar.css';
import {
    Collapse,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    Dropdown,
    DropdownToggle,
    DropdownMenu
  } from 'reactstrap';
import { Link } from 'gatsby';

    import logo from '../../assets/global/valor-digital-solutions.svg';

    export default class ValorNavbar extends React.Component {
        constructor(props) {
            super(props);

            this.toggleNavbar = this.toggleNavbar.bind(this);
            this.toggleServices = this.toggleServices.bind(this);
            this.toggleSolutions = this.toggleSolutions.bind(this);
            this.onServicesMouseEnter = this.onServicesMouseEnter.bind(this);
            this.onServicesMouseLeave = this.onServicesMouseLeave.bind(this);
            this.onSolutionsMouseEnter = this.onSolutionsMouseEnter.bind(this);
            this.onSolutionsMouseLeave = this.onSolutionsMouseLeave.bind(this);
            this.state = {
              collapsed: true,
              servicesdropdownOpen: false,
                solutionsdropdownOpen: false
            };
          }
          toggleNavbar() {
            this.setState({
              collapsed: !this.state.collapsed
            });
          }
          toggleServices() {
            this.setState({
              servicesdropdownOpen: !this.state.servicesdropdownOpen
            });
          }
        toggleSolutions() {
            this.setState({
                solutionsdropdownOpen: !this.state.solutionsdropdownOpen
            });
        }
          onServicesMouseEnter() {
            this.setState({servicesdropdownOpen: true});
          }

          onServicesMouseLeave() {
            this.setState({servicesdropdownOpen: false});
          }
        onSolutionsMouseEnter() {
            this.setState({solutionsdropdownOpen: true});
        }

        onSolutionsMouseLeave() {
            this.setState({solutionsdropdownOpen: false});
        }
        render() {
          return (
            <div>
                <Navbar  expand="lg">
                    <NavbarBrand className="navbar-brand" href="/">
                        <img src={logo} width="220" height="52" alt="Valor Digital Solutions"/>
                    </NavbarBrand>
                    <div className="navbar-toggler btn-mobile-menu mr-1" >
                        <input type="checkbox" id="i-hamburger" className="check-hamburger" onClick={this.toggleNavbar}/>
                        <label htmlFor="i-hamburger">
                            <div className="icon-hamburger">
                                <span className="h-top"></span>
                                <span className="h-middle"></span>
                                <span className="h-bottom"></span>
                            </div>
                        </label>
                    </div>

                    <Collapse isOpen={!this.state.collapsed} navbar >
                        {/* Mobile Navigation menu */}
                        <div className="mob-navbar-collapse d-lg-none" id="navbarNavAltMarkup">
                            <div className="navbar-nav ml-auto">

                                <div className="mob-nav-item"><a className="mob-nav-link" href="/about">About</a></div>
                                <div className="mob-nav-item"><a className="mob-nav-link" href="/services">Services</a></div>
                                <div className="mob-nav-item"><a className="mob-nav-link" href="/solutions">Solutions</a></div>


                                {/* END: sample mobile sub drop-item */}
                            </div>
                        </div>
                        {/* END: Mobile Navigation menu */}

                        {/* Desktop Navigation  */}
                        <div className="ml-auto d-none d-lg-block desk-nav">
                            <Nav navbar>
                                <NavItem>
                                    <Link className="nav-link" to="/about" activeClassName="valor-active-item-navbar" ><span>About</span></Link>
                                </NavItem>
                                <div>
                                <Dropdown nav onMouseOver={this.onServicesMouseEnter} onMouseLeave={this.onServicesMouseLeave} isOpen={this.state.servicesdropdownOpen} toggle={this.toggleServices} className="valor-dropdown">
                                    <DropdownToggle nav caret>
                                        <NavItem>
                                            <Link className="nav-link valor-linkitem" to="/services" activeClassName="valor-active-item-navbar" ><span>Services</span></Link>
                                        </NavItem>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <a className="drop-header mt3" href="/services/digitalmarketing">Digital Marketing</a>
                                        <a className="dropdown-item" href="/solutions/dynamics365"><span>SEO</span></a>
                                        <div className="drop-header mt-3">Web solutions</div>
                                        <a className="dropdown-item" href="/"><span>Adobe Experience Manager</span></a>
                                        <a className="dropdown-item" href="/"><span>SiteCore</span></a>
                                        <div className="drop-header mt-3">Apps</div>
                                        <a className="dropdown-item" href="/"><span>iOS</span></a>
                                        <a className="dropdown-item" href="/"><span>Android</span></a>
                                        <div className="drop-header mt-3">Infrastructure</div>
                                        <a className="dropdown-item" href="/"><span>Microsoft Azure</span></a>
                                        <a className="dropdown-item" href="/"><span>Amazon Web Services</span></a>
                                    </DropdownMenu>
                                </Dropdown>
                                </div>
                                <div>
                                <Dropdown nav onMouseOver={this.onSolutionsMouseEnter} onMouseLeave={this.onSolutionsMouseLeave} isOpen={this.state.solutionsdropdownOpen} toggle={this.toggleSolutions} className="valor-dropdown">
                                    <DropdownToggle nav caret>
                                        <NavItem>
                                            <Link className="nav-link valor-linkitem" to="/solutions" activeClassName="valor-active-item-navbar" ><span>Solutions</span></Link>
                                        </NavItem>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <div className="drop-header">CRM</div>
                                            <a className="dropdown-item" href="/solutions/dynamics365"><span>Dynamics CRM</span></a>
                                            <a className="dropdown-item" href="/"><span>SalesForce</span></a>
                                        <div className="drop-header mt-3">Web solutions</div>
                                            <a className="dropdown-item" href="/"><span>Adobe Experience Manager</span></a>
                                            <a className="dropdown-item" href="/"><span>SiteCore</span></a>
                                        <div className="drop-header mt-3">Apps</div>
                                            <a className="dropdown-item" href="/"><span>iOS</span></a>
                                            <a className="dropdown-item" href="/"><span>Android</span></a>
                                        <div className="drop-header mt-3">Infrastructure</div>
                                            <a className="dropdown-item" href="/"><span>Microsoft Azure</span></a>
                                            <a className="dropdown-item" href="/"><span>Amazon Web Services</span></a>
                                    </DropdownMenu>
                                </Dropdown>
                                </div>
                                <NavItem >
                                    <Link to="/contact" className="valor-letschat-item-navbar nav-link">Let's Chat</Link>
                                </NavItem>

                            </Nav>
                        </div>
                        {/* End: Desktop Navigation */}
                    </Collapse>
                </Navbar>
            </div>
          );
        }
      }
