import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./HomeCarousel.css";
import { graphql, StaticQuery } from "gatsby";

export default () => (
  <StaticQuery
    query={graphql`
      query HomeCarouselQuery {
        allStrapiHomepage {
          edges {
            node {
              OneCarouselTitle
              OneCarouselBlurb
              TwoCarouselTitle
              TwoCarouselBlurb
              ThreeCarouselTitle
              ThreeCarouselBlurb
            }
          }
        }
      }
    `}
    render={data => (
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        showArrow={false}
        emulateTouch={true}
        showStatus={false}
        showIndicators={false}
        infiniteLoop={false}
      >
        <div>
          <div className="carousel-img carousel1">
            {data.allStrapiHomepage.edges.map(document => (
              <div className="carousel-legend">
                <h1 className="l-title">{document.node.OneCarouselTitle}</h1>
                <p className="l-subtitle">{document.node.OneCarouselBlurb}</p>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="carousel-img carousel2">
            {data.allStrapiHomepage.edges.map(document => (
              <div className="carousel-legend">
                <h1 className="l-title">{document.node.TwoCarouselTitle}</h1>
                <p className="l-subtitle">{document.node.TwoCarouselBlurb}</p>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="carousel-img carousel3 ">
            {data.allStrapiHomepage.edges.map(document => (
              <div className="carousel-legend">
                <h1 className="l-title">{document.node.ThreeCarouselTitle}</h1>
                <p className="l-subtitle">{document.node.ThreeCarouselBlurb}</p>
              </div>
            ))}
          </div>
        </div>
      </Carousel>
    )}
  />
);
