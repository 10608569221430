import React from 'react';
import './HomeWWD.css';
import { Row, Col } from 'reactstrap';

export default class HomePixelArt extends React.Component {
  render() {
    return (
    <Row className="valor-sec sec-services">
        <Col sm="12" className="text-center mb-5">
        <h1 className="sec-header">What We Do</h1>
            <p className="font-weight-bold d-sm-none d-md-block">
                We offer a range of Digital Solutions and Services and ensure we are your one stop provider.
                <br/>
                We will deliver your goals through our seamless integration of services.
            </p>
            <p className="font-weight-bold d-none d-sm-block d-md-none">
                We offer a range of Digital Solutions and Services and ensure we are your one stop provider. We will deliver your goals through our seamless integration of services.
            </p>
        </Col>
        <Col className="d-md-flex justify-content-lg-center justify-content-md-around">
            <Col md="5" lg="4" xl="3" className="homeSS">
                <div className="d-flex align-items-center h-25 ">
                    <h1>Solutions</h1>            
                </div>
                <div className="mb-lg-4">
                    <p>Our solutions portfolio includes the leading market solutions and platforms</p>
                </div>
                <div className="mb-md-5 pt-lg-2">
                    <ul>
                        <li>Web Solutions</li>
                        <li>CRM Systems</li>
                        <li>Mobile Apps</li>
                        <li>Cloud Solutions</li>
                    </ul>
                </div>
            </Col>
            <Col md="5" lg="4" xl="3" className="homeSS homeService">    
                <div className="d-flex align-items-center h-25">
                    <h1>Services</h1>            
                </div>
                <div>
                    <p>Professional quality services that integrate with your business and delivers results</p>
                </div>
                <div className="pt-lg-2">
                    <ul>
                        <li>Digital Marketing</li>
                        <li>Consulting</li>
                        <li>UX Services</li>
                        <li>Software Development</li>
                    </ul>
                </div>
            </Col>
        </Col>
    </Row>
    );
  }
}