import React from "react";
import { StaticQuery, graphql } from "gatsby";
import "./HomePixelArt.css";
import { Row, Col } from "reactstrap";

export default () => (
  <StaticQuery
    query={graphql`
      query HomePixelArtQuery {
        allStrapiHomepage {
          edges {
            node {
              HomePixelArt
            }
          }
        }
      }
    `}
    render={data => (
      <Row className="pixelArt mr-0 ml-0">
        <Col
          lg={{ size: "8", offset: "2" }}
          className="d-flex justify-content-center"
        >
          <ul>
            {data.allStrapiHomepage.edges.map(document => (
              <h2>
                <h3>{document.node.HomePixelArt}</h3>
              </h2>
            ))}
          </ul>
        </Col>
      </Row>
    )}
  />
);