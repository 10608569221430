import React from "react";
import PropTypes from "prop-types";

import "../../bootstrap.min.css";
import "../../master.css";

import TopBar from "./TopBar";
import ValorNavbar from "./ValorNavbar";
import ValorBottomNav from "./ValorBottomNav";

const Layout = ({ children }) => (
  <div>
    <TopBar />
    <ValorNavbar />
    <div>
      <main>{children}</main>
      <footer>
        <ValorBottomNav />
      </footer>
    </div>
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
